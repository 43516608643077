<template>
  <div class="filter-datepicker">
    <p>Período:</p>
    <div v-if="isFiltering">
      <VueHotelDatepicker
        class="filter-datepicker__input"
        format="DD/MM/YYYY"
        :placeholder="calendarioTexto.placeholder"
        :separator="calendarioTexto.toText"
        :weekList="calendarioTexto.weekList"
        :monthList="calendarioTexto.monthList"
        :fromText="calendarioTexto.fromText"
        :toText="calendarioTexto.toText"
        :resetText="calendarioTexto.resetText"
        :confirmText="calendarioTexto.confirmText"
        minDate="01/01/1970"
        :maxDate="minDate"
        :startDate="from"
        :endDate="to"
        :mobile="isMobile"
        @confirm="atualizaCalendario"
        @reset="resetCalendario"
        @close="closeCalendario"
      />
      <IconSelectDown class="filter-datepicker__icone"></IconSelectDown>
    </div>
    <div v-if="!isFiltering" :class="!isFiltering ? '--active' : ''">
      <b>Mês atual</b>
      <b-button variant="outline-dark" size="sm" @click="showDatePicker">
        filtrar
      </b-button>
    </div>
  </div>
</template>

<script>
import { nowDateString } from "@/utils/date";
import VueHotelDatepicker from "@northwalker/vue-hotel-datepicker";
import IconSelectDown from "@/components/icones/IconSelectDown.vue";
export default {
  props: {
    onFilter: { type: Function },
  },
  components: {
    VueHotelDatepicker,
    IconSelectDown,
  },
  data() {
    return {
      isFiltering: false,
      minDate: new Date(),
      from: null,
      to: null,
      calendarioTexto: {
        placeholder: "Selecione o período",
        fromText: "De",
        toText: "a",
        resetText: "Limpar",
        confirmText: "Confirmar",
        weekList: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthList: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
      },
      isMobile: window.innerWidth < 768 ? "mobile" : "desktop",
    };
  },
  methods: {
    fileNameNow(preString) {
      return `${preString}_${nowDateString()}.xls`;
    },
    closeCalendario() {},
    resetCalendario() {
      // this.isFiltering = false;
      // this.from = new Date();
      // this.to = new Date();
      // this.sendFilter();
    },
    atualizaCalendario(data) {
      this.from = data.start;
      this.to = data.end;
      this.sendFilter();
    },
    sendFilter() {
      let start = this.from.split("/");
      let end = this.to.split("/");
      const data = {
        start: `${start[2]}-${start[1]}-${start[0]}`,
        end: `${end[2]}-${end[1]}-${end[0]}`,
      };
      this.$emit("getDataSelect", data);
    },
    showDatePicker() {
      this.isFiltering = true;
    },
  },
};
</script>

<style lang="scss">
.filter-datepicker {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  .vhd-container {
    height: 20px;
    margin-left: 10px;
    width: auto;
    text-align: center;
    padding-right: 25px;
    cursor: pointer;
    margin-right: 0;
    max-width: 270px;
    position: relative;
    z-index: 1;
  }
  .vhd-input {
    margin-bottom: 0;
    height: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #2c2c2c;
    min-width: auto !important;
    width: 270px !important;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    &::placeholder {
      font-size: 20px;
      text-align: center;
    }
  }
  &__icone {
    position: relative;
    position: absolute;
    top: -11px;
    right: -15px;
    z-index: 0;
    cursor: pointer;
  }
  &__input {
    margin-left: 20px;
    input {
      min-width: auto !important;
    }
  }
  > div {
    align-items: center;
    display: flex;
    font-size: 14px;
    padding: 0;
    height: 20px;
    &.--active {
      height: 30px;
    }
    b {
      color: $cor-escura;
      font-weight: bold;
      margin-right: 8px;
      margin-left: 10px;
    }
  }
}
</style>
